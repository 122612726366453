import React, { useState, useEffect } from 'react';
import hello from './img/images.jpg';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CommanInput from './Constent/CommanInput';
import CommanDropDown from './Constent/CommanDropDown';
import Imges from './Constent/Imges';
// import { Button } from 'react-bootstrap';
import { GET_WITH_TOKEN, GET_WITH_TOKEN_PARAMS, Post_WITH_TOKEN } from '../Backend/Bakend';
import { showToast } from './Constent/Toast';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from "draft-js";
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import BasicPagination from './Pagination';
import EmojiPicker from 'emoji-picker-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { styled } from '@mui/material';


const Post = () => {
  const [PostList, setPostList] = useState([]);
  const [PostList1, setPostList1] = useState([]);
  const [totalPage, setTotalPage] = useState("");
  const [IsEdit, setIsEdit] = useState(false);
  const [IsEditId, setIsEditId] = useState("");
  const [index, setIndex] = useState();
  const [keyOptions, setKeyOptions] = useState([]);
  const [Data, setData] = useState({
    post_name: "",
    slug: "",
    post_title: "",
    post_up_title: "",
    post_up_fst_desc: "",
    post_up_sec_desc: "",
    post_mid_title: "",
    post_mid_description: "",
    post_short_title: "",
    post_expolore_section: "",
    post_activity_section: "",
    photo_galary_title: "",
    post_consider_section: "",
    post_explore_more_title: "",
    post_top_image: "",
    post_upper_left_image: "",
    post_upper_right_image: "",
    post_short_image: "",
    publish: 1,
    post_plan_section: "",
    status: 1,
    trending_destination_title: "",
    things_to_do_title:"",
    places_visit_title:"",
  });
  const [Id, setId] = useState()
  const [PostImage, setPostImage] = useState('')
  const [PostUpperRight, setPostUpperRight] = useState('')
  const [PostUpperImg, setPostUpperImg] = useState('')
  const [PostShortImg, setPostShortImg] = useState('')
  const [errors, setErrors] = useState({});
  const [Array1, setArray1] = useState([]);
  const [top, setTop] = useState();
  const [short, setShort] = useState();
  const [topSecond, setTopSecond] = useState();
  const [topFirst, setTopFirst] = useState();
  const [Images, setImages] = useState([]);
  const [Data1, setData1] = useState([]);
  const [questions, setQuestions] = useState([])
  const [gallery, setgallery] = useState([]);
  const [show, setShow] = useState(false);
  const [CurrentPage, setCurrentPage] = useState(1)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    // PostCreate();
    if (IsEdit) {
      PostCreate();
    }
    else {
      const validationErrors = validateForm(Data);
      if (Object.keys(validationErrors).length === 0) {
        // Proceed with form submission
        PostCreate();
      } else {
        showToast("All fields are required");
      }
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    Object.keys(formData).forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = `${fieldName} is required`;
      }
    });
    return errors;
  };

  const getPostShow12 = async () => {
    GET_WITH_TOKEN("/api/getallpost", "",
      onSuccess => {
        console.log('Success:', onSuccess);
        if (!!onSuccess) {
          setPostList1(onSuccess.data);
          const convertedArray = Object.entries(onSuccess?.key_image_options).map(([id, name]) => ({ id, name }));
          setKeyOptions(convertedArray)
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        // showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        // showToast("Somthing Wents to worng")

      })
  }

  const Array = [{ id: 1, name: "My First", value: 1 }, { id: 1, name: "My First", value: 1 }, { id: 1, name: "My First", value: 1 }]

  console.log(Images, "Images");

  const AddNewKey = () => {
    const Array = [...Data1];
    Array.push({ name: "", value: "", key_image: "" });
    setData1(Array);
  }

  const AddNewQuestions = () => {
    const Array = [...questions];
    Array.push({ name: "", value: "" });
    setQuestions(Array);
  }



  const DeleteQuestion = (index) => {
    const Array = [...questions];

    console.log(Array.splice(index, 1), "hello");

    setQuestions(Array)

  }

  const Delete = (index) => {
    const Array = [...Data1];

    console.log(Array.splice(index, 1), "hello");

    setData1(Array)

  }



  const [ImagesArray1, setImagesArray1] = useState([]);
  const [ImagesArray2, setImagesArray2] = useState([]);

  const AddImages = (e) => {
    const fileList = e.target.files; // Get the FileList object
    const updatedImagesArray1 = [...ImagesArray1];
    const updatedImages = [...ImagesArray2];

    // Iterate over each file in the FileList
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      updatedImagesArray1.push(file); // Push each file to ImagesArray1

      const imageUrl = URL.createObjectURL(file); // Create URL for preview
      updatedImages.push({ image: imageUrl }); // Push image URL to Images
    }

    // Update state with the arrays containing all selected files and images
    setImagesArray1(updatedImagesArray1);
    // setImages(updatedImages);
    setImagesArray2(updatedImages);
  };


  const DeleteImage = (index) => {
    const ArrayNew = [...ImagesArray1]
    ArrayNew.splice(index, 1)
    setImagesArray1(ArrayNew)
    const Array = [...ImagesArray2]
    Array.splice(index, 1)
    setImagesArray2(Array);
  }

  const DeleteTopImage = (index) => {
    const Array = [...Images]
    Array.splice(index, 1)
    setImages(Array);
  }


  const [ImagesArray, setImagesArray] = useState([]);


  console.log("data", Data);





  const AddPostImages = (e) => {
    const fileList = e.target.files; // Get the FileList object
    const updatedImagesArray = [...ImagesArray];
    const updatedArray1 = [...gallery];

    // Iterate over each file in the FileList
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      updatedImagesArray.push(file); // Push each file to ImagesArray

      const imageUrl = URL.createObjectURL(file); // Create URL for preview
      updatedArray1.push({ image: imageUrl }); // Push image URL to Array1
    }

    // Update state with the arrays containing all selected files and image URLs
    setImagesArray(updatedImagesArray);
    setgallery(updatedArray1);
  };


  const DeletePostImage = (index) => {
    const ArrayNew = [...ImagesArray]
    ArrayNew.splice(index, 1)
    setImagesArray(ArrayNew)
    const Array = [...gallery]
    Array.splice(index, 1)
    setgallery(Array);
  }

  const DeletePostTopImage = (index) => {
    const Array = [...Array1]
    Array.splice(index, 1)
    setArray1(Array);
  }

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedIds1, setSelectedIds1] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedName1, setSelectedName1] = useState([]);


  console.log(selectedName, "selectedName");


  const handleSelectionChange = (e, name) => {
    console.log(name, "nasme");
    const selectedId = parseInt(e);
    console.log(e, "eeeee======>", selectedIds)
    if (!selectedIds.includes(selectedId)) {
      setSelectedIds([...selectedIds, selectedId]);
      // setSelectedName([...selectedName,selectedId])
    }

  };

  const handleSelectionChange1 = (e, name) => {
    console.log(name, "nasme");
    const selectedId = parseInt(e);
    if (!selectedIds1.includes(selectedId)) {
      setSelectedIds1([...selectedIds1, selectedId]);
      // setSelectedName([...selectedName,selectedId])
    }
  };



  const PostCreate = async () => {
    const string = selectedIds.join(',');
    const string1 = selectedIds1.join(',');


    const contentState = Data?.post_expolore_section.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);

    const contentState1 = Data?.post_activity_section.getCurrentContent();
    const rawContentState1 = convertToRaw(contentState1);
    const htmlContent1 = draftToHtml(rawContentState1);

    const contentState2 = Data?.post_consider_section.getCurrentContent();
    const rawContentState2 = convertToRaw(contentState2);
    const htmlContent2 = draftToHtml(rawContentState2);


    const contentState3 = Data?.post_mid_description.getCurrentContent();
    const rawContentState3 = convertToRaw(contentState3);
    const htmlContent3 = draftToHtml(rawContentState3);

    const contentState4 = Data?.post_up_fst_desc.getCurrentContent();
    const rawContentState4 = convertToRaw(contentState4);
    const htmlContent4 = draftToHtml(rawContentState4);

    const contentState5 = Data?.post_up_sec_desc.getCurrentContent();
    const rawContentState5 = convertToRaw(contentState5);
    const htmlContent5 = draftToHtml(rawContentState5);

    const contentState6 = Data?.post_plan_section.getCurrentContent();
    const rawContentState6 = convertToRaw(contentState6);
    const htmlContent6 = draftToHtml(rawContentState6);

    var formdata = new FormData();
    formdata.append("post_name", Data?.post_name);
    formdata.append("post_slug", Data?.slug);
    formdata.append("post_title", Data?.post_title);
    formdata.append("post_up_title", Data?.post_up_title);
    formdata.append("post_up_fst_desc", htmlContent4);
    formdata.append("post_up_sec_desc", htmlContent5);
    formdata.append("post_mid_title", Data?.post_mid_title);
    formdata.append("post_mid_description", htmlContent3);
    formdata.append("post_short_title", Data?.post_short_title);
    formdata.append("post_expolore_section", htmlContent);
    formdata.append("post_activity_section", htmlContent1);
    formdata.append("photo_galary_title", Data?.photo_galary_title);
    formdata.append("post_consider_section", htmlContent2);
    formdata.append("post_explore_more_title", Data?.post_explore_more_title);
    formdata.append("post_expore_more_postids", selectedIds.length > 0 ? string : 1);
    formdata.append("trending_destination_title", Data?.trending_destination_title);
    formdata.append("trending_destination_moreids", selectedIds1.length > 0 ? string1 : 1);
    formdata.append('publish', Data?.publish);
    formdata.append('status', Data?.status);
    formdata.append('places_visit_title', Data?.places_visit_title);
    formdata.append('things_to_do_title', Data?.things_to_do_title);
    Data1?.map((res, index) => {
      formdata.append(`short_keys[${index}][key_name]`, res?.name);
      formdata.append(`short_keys[${index}][key_value]`, res?.value);
      formdata.append(`short_keys[${index}][key_image]`, res?.key_image);
    });

    ImagesArray1?.map((res) => {
      formdata.append("post_images[]", res);
    })

    if (IsEdit) {
      Images?.map((res) => {
        formdata.append("post_images_exist[]", res?.image);
      });
      top && formdata.append("post_top_image_exist", top);
      topFirst && formdata.append("post_upper_right_image_exist", topFirst);
      topSecond && formdata.append("post_upper_left_image_exist", topSecond);
      short && formdata.append("post_short_images_exist", short);
      Array1?.map((res) => {
        formdata.append("post_galary_images_exist[]", res?.image);
      });

      formdata.append("_method", "PUT");
    }

    questions?.map((res, index) => {
      formdata.append(`question_keys[${index}][key_name]`, res?.name);
      formdata.append(`question_keys[${index}][key_value]`, res?.value);
    })
    PostImage && formdata.append("post_top_image", PostImage);
    PostUpperImg && formdata.append("post_upper_left_image", PostUpperImg);
    PostUpperRight && formdata.append("post_upper_right_image", PostUpperRight);
    PostShortImg && formdata.append("post_short_images", PostShortImg);
    formdata.append("post_plan_section", htmlContent6)

    ImagesArray?.map((res) => {
      formdata.append("post_galary_images[]", res);
    })



    Post_WITH_TOKEN(IsEdit ? `/api/posts/${Id}` : `/api/posts`, formdata, onSuccess => {
      console.log('Success:', onSuccess);
      showToast(onSuccess.message);
      clearStateValues();
      getPostShow(CurrentPage);
    },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")
      })
  }


  const getPostShow = async (page) => {
    GET_WITH_TOKEN(`/api/posts?page=${page}`, "",
      onSuccess => {
        console.log('Success:', onSuccess);
        if (!!onSuccess) {
          setPostList(onSuccess?.data?.data);
          setTotalPage(onSuccess?.data?.last_page);
          setCurrentPage(onSuccess?.data?.current_page);
        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }


  useEffect(() => {
    getPostShow12()
    getPostShow(1);
  }, []);

  const ShowById = (id) => {
    setId(id);
    GET_WITH_TOKEN_PARAMS("/api/posts", id,
      onSuccess => {
        console.log('Success:', onSuccess.publish);
        if (!!onSuccess) {
          showToast(onSuccess?.message || onSuccess?.msg || "Success");
          const blocksFromHTML = convertFromHTML(onSuccess?.data?.post_expolore_section);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML);
          let data = EditorState.createWithContent(contentState);
          const blocksFromHTML1 = convertFromHTML(onSuccess?.data?.post_activity_section);
          const contentState1 = ContentState.createFromBlockArray(blocksFromHTML1);
          let data1 = EditorState.createWithContent(contentState1);
          const blocksFromHTML2 = convertFromHTML(onSuccess?.data?.post_consider_section);
          const contentState2 = ContentState.createFromBlockArray(blocksFromHTML2);
          let data2 = EditorState.createWithContent(contentState2);
          const blocksFromHTML3 = convertFromHTML(onSuccess?.data?.post_mid_description);
          const contentState3 = ContentState.createFromBlockArray(blocksFromHTML3);
          let data3 = EditorState.createWithContent(contentState3);
          const blocksFromHTML4 = convertFromHTML(onSuccess?.data?.post_up_fst_desc);
          const contentState4 = ContentState.createFromBlockArray(blocksFromHTML4);
          let data4 = EditorState.createWithContent(contentState4);
          const blocksFromHTML5 = convertFromHTML(onSuccess?.data?.post_up_sec_desc);
          const contentState5 = ContentState.createFromBlockArray(blocksFromHTML5);
          let data5 = EditorState.createWithContent(contentState5);
          const blocksFromHTML6 = convertFromHTML(onSuccess?.data?.post_plan_section);
          const contentState6 = ContentState.createFromBlockArray(blocksFromHTML6);
          let data6 = EditorState.createWithContent(contentState6);
          const filteredImages = onSuccess?.data?.images?.filter(image => image.image_type == "header");
          const filteredImages1 = onSuccess?.data?.images?.filter(image => image.image_type == "top");
          const filteredImages2 = onSuccess?.data?.images?.filter(image => image.image_type == "top_second");
          const filteredImages3 = onSuccess?.data?.images?.filter(image => image.image_type == "top_first");
          const filteredImages4 = onSuccess?.data?.images?.filter(image => image.image_type == "gallery");
          const filteredImages5 = onSuccess?.data?.images?.filter(image => image.image_type == "short");
          setTop(filteredImages1[0]?.img_path)
          setTopFirst(filteredImages3[0]?.img_path);
          setTopSecond(filteredImages2[0]?.img_path);
          setShort(filteredImages5[0]?.img_path);
          let images = filteredImages.map((res) => {
            return { image: res.img_path }
          });
          let images1 = filteredImages4.map((res) => {
            return { image: res.img_path }
          });
          setArray1(images1)
          setImages(images);
          setData1(onSuccess?.data?.short_keys)
          setQuestions(onSuccess?.data?.question_keys)
          // setSelectedName(PostList1.filter(item => onSuccess?.post_expore_more_postids?.split(",").includes(item.id.toString())))
          // setSelectedName1(PostList1.filter(item => onSuccess?.trending_destination_moreids?.split(",").includes(item.id.toString())))

          setData({
            post_name: onSuccess.data.post_name,
            slug: onSuccess.data.post_slug,
            post_title: onSuccess.data.post_title,
            post_up_title: onSuccess.data.post_up_title,
            post_up_fst_desc: data4,
            post_up_sec_desc: data5,
            post_mid_title: onSuccess.data.post_mid_title,
            post_mid_description: data3,
            post_short_title: onSuccess.data.post_short_title,
            post_expolore_section: data,
            post_activity_section: data1,
            photo_galary_title: onSuccess.data.photo_galary_title,
            post_consider_section: data2,
            post_explore_more_title: onSuccess.data.post_explore_more_title,
            post_top_image: filteredImages1[0]?.img_path,
            post_upper_left_image: filteredImages2[0]?.img_path,
            post_upper_right_image: filteredImages3[0]?.img_path,
            post_short_image: filteredImages5[0]?.img_path,
            post_plan_section: data6,
            status: onSuccess?.data?.status,
            publish: onSuccess?.data?.publish,
            trending_destination_title: onSuccess?.data?.trending_destination_title,
            places_visit_title: onSuccess?.data?.places_visit_title,
            things_to_do_title:onSuccess?.data?.things_to_do_title,
          });
          let Array = onSuccess?.data?.post_expore_more_postids?.split(",").map(Number)
          let Array1 = onSuccess?.data?.trending_destination_moreids?.split(",").map(Number)
          setSelectedIds(Array || [])
          setSelectedIds1(Array1 || [])
          setSelectedName(PostList1.filter(item => onSuccess?.data?.post_expore_more_postids?.split(",").includes(item.id.toString()))||[])
          setSelectedName1(PostList1.filter(item => onSuccess?.data?.trending_destination_moreids?.split(",").includes(item.id.toString()))||[])


        }
      },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")

      })
  }




  const clearStateValues = () => {
    setPostList([]);
    setTotalPage("");
    setIsEdit(false);
    setIsEditId("");
    setData({
      post_name: "",
      slug: "",
      post_title: "",
      post_up_title: "",
      post_up_fst_desc: "",
      post_up_sec_desc: "",
      post_mid_title: "",
      post_mid_description: "",
      post_short_title: "",
      post_expolore_section: "",
      post_activity_section: "",
      photo_galary_title: "",
      post_consider_section: "",
      post_explore_more_title: "",
      post_top_image: "",
      post_upper_left_image: "",
      post_upper_right_image: "",
      post_short_image: "",
      post_plan_section: "",
      trending_destination_title: ""
    });
    setId(null);
    setPostImage('');
    setPostUpperRight('');
    setPostUpperImg('');
    setPostShortImg('');
    setErrors({});
    setArray1([]);
    setTop(undefined);
    setShort(undefined);
    setTopSecond(undefined);
    setTopFirst(undefined);
    setImages([]);
    setData1([]);
    setQuestions([]);
    setgallery([]);
    setImagesArray1([]);
    setImagesArray2([]);
    setImagesArray([]);
    setSelectedIds([]);
    setSelectedName([]);
  };


  const PublishData = [
    { id: 1, name: "Yes", value: 1 }, { id: 0, name: "No", value: 0 }
  ]

  const StatusData = [
    { id: 1, name: "Active", value: 1 }, { id: 0, name: "InActive", value: 0 }
  ]

  const handleRemove = (selectedList, removedItem) => {
    console.log(removedItem, "removedItem");
    const updatedIds = selectedIds.filter(id => id !== removedItem?.id);
    setSelectedIds(updatedIds);
    // Your logic for handling the removal of items here
};
const handleRemove1 = (selectedList, removedItem) => {
  console.log(removedItem, "removedItem");
  const updatedIds = selectedIds1.filter(id => id !== removedItem?.id);
  setSelectedIds1(updatedIds);
  // Your logic for handling the removal of items here
};


  return (
    <div>
      <div className='container mt-1'>
        <div className='row'>
          <div className='col-lg-12  '>

            <div className='card'>
              <div className='card-header'>
                <p style={{ fontSize: '25px' }}>Post Product Filter</p>



                <div class="mb-3  mt-4 d-flex">
                  <input type="text" class="form-control " id="exampleFormControlInput1"
                    placeholder="Enter Name" style={{ width: '20%' }} />

                  <button type='btn' className='ms-4' style={{ backgroundColor: '#008c93', color: '#fff', border: 'none', padding: '7px 15px', borderRadius: '8px' }}>Search</button>

                  <button type='btn' className='ms-2' style={{ backgroundColor: 'transparent', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Reset</button>


                  <button type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ backgroundColor: '#366fe9', color: '#000', padding: '7px 15px', borderRadius: '8px', border: '1px solid gray' }}>Add New</button>
                </div>
                <div>

                </div>
              </div>
              <div className='card-body' style={{ height: 550, overflowY: "scroll" }}>
                <p style={{ fontSize: '20px' }}>View All Products</p>

                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">image</th>
                      <th scope="col">Name</th>
                      <th scope="col">slug</th>
                      <th scope="col">description</th>
                      <th scope="col">post</th>
                      <th scope="col">Parent</th>
                      {/* <th scope="col">Country</th>
      <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      PostList?.length > 0 ?
                        PostList?.map((res, index) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td><div><img src={hello} alt='img' style={{ width: '60px' }} /></div></td>
                                <td>{res?.name}</td>
                                <td>{res?.post_slug}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <div >
                                    {/* <button className='py-2' style={{backgroundColor:'#3dc934', color:'#fff',
           border:'none', borderRadius:'4px'}}>Show</button>*/}
                                    <button className='py-2 mx-2' style={{
                                      backgroundColor: '#3cc3b0', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }} onClick={() => { ShowById(res?.id); setIsEdit(true); setIsEditId(res?.id) }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Edit</button>
                                    <button className='py-2' style={{
                                      backgroundColor: '#9ba322', color: '#fff',
                                      border: 'none', borderRadius: '4px'
                                    }}>Delete</button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        }) : null
                    }



                  </tbody>

                </table>
                {
                  PostList?.length > 0 ? null :
                    <div className="data-not-found">
                      <h2 className="bounce">Data Not Found</h2>
                      <p className="fade-in">Sorry, the requested data could not be found.</p>
                    </div>
                }
              </div>
              <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <BasicPagination count={totalPage} onChange={(e) => { getPostShow(e) }} />
              </div>
            </div>



          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '85%' }}>
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">Add Post</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">

          <CommanInput multiple={true} placeholder={"Post Images"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Header Images"} onChange={(e) => {

            AddImages(e)
          }} />

          <div className='w-100 row'>
            {
              Images?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeleteTopImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <div className='w-100 row'>
            {
              ImagesArray2?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeleteImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <CommanInput value={Data?.post_name} placeholder={"Enter post Name"} type='text' describtionShow={true} desTitle={"post Name"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_name: e }) }} />
          <CommanInput value={Data?.slug} placeholder={"Enter slug"} type='text' describtionShow={true} desTitle={" Slug"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, slug: e }) }} />
          <CommanInput placeholder={"Post Top image"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Post image"} onChange={(e) => {
            setData({ ...Data, post_top_image: URL.createObjectURL(e.target.files[0]) });
            setPostImage(e.target.files[0], setTop())
          }} />
          <div className='col-2'>
            {
              Data?.post_top_image && <img className='w-100' src={Data.post_top_image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
            }
          </div>
          <CommanInput value={Data?.post_title} placeholder={"Enter Post Title"} type='text' describtionShow={true} desTitle={" Post Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_title: e }) }} />
          <CommanInput value={Data?.post_up_title} placeholder={"Enter Post Content Title"} type='text' describtionShow={true} desTitle={" Post Content Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_up_title: e }) }} />

          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Content Paragraph 1</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_up_fst_desc}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_up_fst_desc: e }) }}
              /></div>
          </div>


          {/* <CommanInput   value={Data?.post_up_fst_desc} title={"Enter Post Upper First Description"} placeholder={"Enter Post Description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={" Post Upper First Description"} onChange={(e)=>{console.log(e,"data");setData({...Data,post_up_fst_desc:e})}} style={{height:160}}/> */}
          <CommanInput placeholder={"Post Upper Right image"} fileInput={true} input={false} textArea={false} type='text' describtionShow={true} desTitle={"Paragraph 1 image"} onChange={(e) => {
            setData({ ...Data, post_upper_right_image: URL.createObjectURL(e.target.files[0]) });
            setPostUpperRight(e.target.files[0]); setTopFirst()
          }} />
          <div className='col-2'>
            {
              Data?.post_upper_right_image &&
              <img className='w-100' src={Data?.post_upper_right_image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />

            }
          </div>
          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Content Paragraph 2</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_up_sec_desc}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_up_sec_desc: e }) }}
              /></div>
          </div>
          {/* <CommanInput value={Data?.post_up_sec_desc} title={"Enter Post Upper Second Description"} placeholder={"Enter Post Second Description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={" Post Upper Second Description"} onChange={(e)=>{console.log(e,"data");setData({...Data,post_up_sec_desc:e})}} style={{height:160}}/> */}
          <CommanInput placeholder={"Post post Upper left image"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Paragraph 2 image"} onChange={(e) => {
            setData({ ...Data, post_upper_left_image: URL.createObjectURL(e.target.files[0]) });
            setPostUpperImg(e.target.files[0]);
            setTopSecond()
          }} />
          <div className='col-2'>
            {
              Data?.post_upper_left_image && <img className='w-100' src={Data.post_upper_left_image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
            }
          </div>
          <CommanInput value={Data?.post_mid_title} placeholder={"Enter How to Reach Title"} type='text' describtionShow={true} desTitle={"How to Reach Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_mid_title: e }) }} />
          {/* <CommanInput value={Data?.post_mid_description}  title={"Enter Post Middel Description"} placeholder={"Enter Post Middel Description"} type='text' describtionShow={true} input={false} textArea={true} desTitle={" Post Middel Description"} onChange={(e)=>{console.log(e,"data");setData({...Data,post_mid_description:e})}} style={{height:160}}/> */}


          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>How To Reach Description</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_mid_description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_mid_description: e }) }}
              /></div>
          </div>


          <CommanInput value={Data?.post_short_title} placeholder={"Enter Must Know Things Title"} type='text' describtionShow={true} desTitle={"Must Know Things Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_short_title: e }) }} />
          <CommanInput placeholder={"Post Short image"} fileInput={true} input={false} textArea={false} describtionShow={true} desTitle={"Must Know Things image"} onChange={(e) => {
            setData({ ...Data, post_short_image: URL.createObjectURL(e.target.files[0]) });
            setPostShortImg(e.target.files[0], setShort());
          }} />

          <div className='col-2'>
            {
              Data?.post_short_image && <img className='w-100' src={Data.post_short_image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
            }
          </div>
          <div onClick={() => { AddNewKey() }} className='w-100 mt-2 mb-2 d-flex' style={{ justifyContent: "flex-start", alignItems: "flex-start", cursor: "pointer" }}>
            <div className='d-flex m-2' style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={Imges?.add} alt='###' style={
                { height: 30 }
              } />
              <h6 className='' style={{ marginLeft: 20, color: "red" }}>Element & Value</h6>
            </div>
          </div>

          <div className='d-flex w-100 row'>
            {
              Data1?.map((res, index) => {
                return (
                  <>
                    <div className='col-4 p-2'>
                      <CommanInput placeholder={"Enter Name"} type='text' describtionShow={true} desTitle={" Name"} onChange={(e) => {
                        const newArray = [...Data1];
                        newArray[index].name = e;
                        setData1(newArray);
                      }}
                        value={res.name}
                      />
                    </div>
                    <div className='col-4 p-2'>
                      <CommanInput placeholder={"Enter value"} type='text' describtionShow={true} desTitle={" value"} onChange={(e) => {
                        const newArray = [...Data1];
                        newArray[index].value = e;
                        setData1(newArray);
                      }} value={res.value} />
                    </div>
                    <CommanDropDown ContainerStyle={{ width: "20%", marginTop: 20 }} displayValue="key_image" useMultiselect={false} title={"Add Image"} desTitle={"Add Image"} Data={keyOptions || []} onChange={(e, name) => {
                      const NewArray = [...Data1]
                      NewArray[index].key_image = e;
                      setData1(NewArray);
                    }} value={res?.key_image} />
                    {/* <div onClick={()=>{handleShow();setIndex(index)}} className='col-1 p-2' style={{marginTop:35,}}>
               <button className='bg-secondary'> {res.key_image === null?"Add":res?.key_image === "undefined"?"Add":res.key_image} </button>
            </div> */}
                    <div onClick={() => { Delete(index) }} className='col-1 p-2 ' style={{ marginTop: 35 }}>
                      <button>Delete</button>
                    </div>
                  </>
                )
              })
            }
          </div>


          <CommanInput value={Data?.places_visit_title} placeholder={"Places To Visit Section Title"} type='text' describtionShow={true} desTitle={" Places To Visit Section"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, places_visit_title: e }) }} />
         

          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Places To Visit Section</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_expolore_section}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_expolore_section: e }) }}
              /></div>
          </div>

          {/* <CommanInput placeholder={"Enter Post Activity Section"} type='text' describtionShow={true} desTitle={" Post Activity Section"} onChange={(e)=>{console.log(e,"data");setData({...Data,post_activity_section:e})}}/> */}
          <CommanInput placeholder={"Things To Do Title"} value={Data?.things_to_do_title} type='text' describtionShow={true} desTitle={" Things To Do Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, things_to_do_title: e }) }} />
          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Things To Do Section</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_activity_section}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_activity_section: e }) }}
              /></div>
          </div>
          <CommanInput value={Data?.photo_galary_title} placeholder={"Enter Photo Gallery Title"} type='text' describtionShow={true} desTitle={" Photo Gallery Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, photo_galary_title: e }) }} />

          <CommanInput multiple={true} placeholder={"Post Gallery Images"} fileInput={true} input={false} textArea={false} type='text' describtionShow={true} desTitle={"Gallery Images"} onChange={(e) => { AddPostImages(e) }} />

          <div className='w-100 row'>
            {
              Array1?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeletePostTopImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          <div className='w-100 row'>
            {
              gallery?.map((res, index) => {
                return (
                  <>
                    <div className='col-2'>
                      <img className='w-100' src={res.image} alt='#' style={{ justifyContent: "center", alignItems: "center", width: 100 }} />
                      <button className='w-100' onClick={() => { DeletePostImage(index) }}>
                        Delete
                      </button>
                    </div>
                  </>
                )
              })
            }
          </div>

          {/* <CommanInput placeholder={"Enter Post Consider Section"} type='text' describtionShow={true} desTitle={" Post Consider Section"} onChange={(e)=>{console.log(e,"data");setData({...Data,post_consider_section:e})}}/> */}

          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>Tips To Consider Section</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_consider_section}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_consider_section: e }) }}
              /></div>
          </div>

          <CommanInput value={Data?.post_explore_more_title} placeholder={"More Destinations Title"} type='text' describtionShow={true} desTitle={" More Destinations Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, post_explore_more_title: e }) }} />

          <CommanDropDown displayValue="post_slug" useMultiselect={true} title={"Pls Select"} desTitle={"Add Related Posts"} Data={PostList1} onChange={(e, name) => { console.log(e, name); handleSelectionChange(e, name) }} slectedData={selectedName || []} onRemove={handleRemove}/>

          <CommanInput value={Data?.trending_destination_title} placeholder={"Trending Destinations Title"} type='text' describtionShow={true} desTitle={"Trending Destinations Title"} onChange={(e) => { console.log(e, "data"); setData({ ...Data, trending_destination_title: e }) }} />

          <CommanDropDown displayValue="post_slug" useMultiselect={true} title={"Add more posts"} desTitle={"Add more posts"} Data={PostList1} onChange={(e, name) => { console.log(e, name); handleSelectionChange1(e, name) }} slectedData={selectedName1 || []} onRemove={handleRemove1}/>

          <div onClick={() => { AddNewQuestions() }} className='w-100 mt-2 mb-2 d-flex' style={{ justifyContent: "flex-start", alignItems: "flex-start", cursor: "pointer" }}>
            <div className='d-flex m-2' style={{ justifyContent: "center", alignItems: "center" }}>
              <img src={Imges?.add} alt='###' style={
                { height: 30 }
              } />
              <h6 className='' style={{ marginLeft: 20, color: "red" }}>Add Faq Asked Questions</h6>
            </div>
          </div>
          <div className='d-flex w-100 row'>
            {
              questions?.map((res, index) => {

                return (
                  <>
                    <div className='col-5 p-2'>
                      <CommanInput textArea={true} input={false} placeholder={"Enter Questions"} type='text' describtionShow={true} desTitle={" Question"} onChange={(e) => {
                        const newArray = [...questions];
                        newArray[index].name = e;
                        setQuestions(newArray);
                      }}
                        value={res.name}
                      />
                    </div>
                    <div className='col-5 p-2'>
                      <CommanInput textArea={true} input={false} placeholder={"Enter Answare"} type='text' describtionShow={true} desTitle={"Answare"} onChange={(e) => {
                        const newArray = [...questions];
                        newArray[index].value = e;
                        setQuestions(newArray);
                      }} value={res.value} />
                    </div>
                    <div onClick={() => { DeleteQuestion(index) }} className='col-2' style={{ marginTop: 35, alignItems: "center", justifyContent: "center" }}>
                      <button>Delete</button>
                    </div>
                  </>
                )
              })
            }
          </div>


          <div class="mb-2">
            <label for="exampleFormControlTextarea1" class="form-label" style={{ fontWeight: '600' }}>CTA Section</label>
            <div className='' style={{ border: "1px solid gray" }}>
              <Editor
                editorState={Data?.post_plan_section}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => { console.log(e, "data"); setData({ ...Data, post_plan_section: e }) }}
              /></div>
          </div>
          <CommanDropDown displayValue="publish" value={Data?.publish} title={"Pls Select"} desTitle={"publish"} Data={PublishData} onChange={(e, name) => { console.log(e, name); setData({ ...Data, publish: e }) }} />
          <CommanDropDown displayValue="Status" value={Data?.status} title={"Pls Select"} desTitle={"Status"} Data={StatusData} onChange={(e, name) => { console.log(e, name); setData({ ...Data, status: e }) }} />

          <Button className='mt-3' onClick={() => { handleSubmit() }} data-bs-dismiss="offcanvas" aria-label="Close">Submit</Button>
        </div>
      </div>


      <Modal
        // {...props}
        show={show} onHide={handleClose} animation={true}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: 'transparent' }}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className='w-100'>
            <EmojiPicker className='w-100' onEmojiClick={(e) => {
              console.log(e, "============>")
              let data = [...Data1];
              data[index].key_image = e?.emoji
              setData1(data);
              handleClose()
            }} />
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={()=>{handleClose()}}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    </div>
  )
}

export default Post;