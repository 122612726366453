
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Context/MyContext';
import { Post_Api } from '../Backend/Bakend';
import { showToast } from './Constent/Toast';


const Login = () => {
  const [formData, setFormData] = useState({
    name: 'manoj sharma',
    email: 'mehta1992mit@gmail.com',
    password: 'manoj@9211'
  });



  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {

    // Basic validation
    if (!formData.name || !formData.email || !formData.password) {
      setError('All fields are required');
      return;
    }
    else {
      Api()
    }
    setFormData({
      name: '',
      email: '',
      password: ''
    });
    setError('');
  };


  const { isLoggedIn, login } = useContext(MyContext);

  const [loader, setloader] = useState(false);


  const Navigate = useNavigate();






  const Api = async () => {
    setloader(true)
    Post_Api("/api/login", JSON.stringify(formData), onSuccess => {
      console.log('Success:', onSuccess);
      if (onSuccess.token_type == "Bearer") {
        showToast("Are you login successfully")
        localStorage.setItem("Token", onSuccess.access_token)
        login(onSuccess.access_token);
        setloader(false)
      }
    },
      onFail => {
        console.log('Fail:', onFail);
        showToast(onFail)
        setloader(false)
      },
      onError => {
        console.error('Error:', onError);
        showToast("Somthing Wents to worng")
        setloader(false)
      })


  }



  return (
    <div className="admin-login-container">
      <h2>Admin Login</h2>
      <div >
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit" onClick={() => { loader ? <></> : handleSubmit() }}>{loader ? "loading..." : "Login"}</button>
      </div>
    </div>
  );
};

export default Login;
