import React,{useState,useEffect}from 'react'
import hello from './img/images.jpg';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GET_WITH_TOKEN, GET_WITH_TOKEN_PARAMS, Post_WITH_TOKEN } from '../Backend/Bakend';
import { showToast } from './Constent/Toast';
import { Button } from 'react-bootstrap';
import CommanInput from './Constent/CommanInput';
import CommanDropDown from './Constent/CommanDropDown';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw} from "draft-js";
import { EditorState, convertFromHTML,ContentState} from 'draft-js';
import BasicPagination from './Pagination';



const Cat = () => {
  const [editorState, seteditorState] = useState("");
  const [PostList, setPostList] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  const [IsEditId, setIsEditId] = useState("");
  const [baseCatList, setbaseCatList] = useState([]);
  const onEditorStateChange = (e)=>{
     seteditorState(e)
  }

 useEffect(() => {
  getCat();
  getPostShow();
  getParentCatShow();
 }, [])
 


 const getPostShow = async()=>{
  GET_WITH_TOKEN("/api/moreposts","",
  onSuccess => {
    console.log('Success:', onSuccess);
   if(!!onSuccess){
    setPostList(onSuccess?.data);
   }
    },
    onFail => {
    console.log('Fail:', onFail);
    showToast(onFail)
    },
    onError => {
    console.error('Error:', onError);
    showToast("Somthing Wents to worng")
  
    })
}
const getParentCatShow = async()=>{
  GET_WITH_TOKEN("/api/categories","",
  onSuccess => {
    console.log('Success:', onSuccess);
   if(!!onSuccess){
    setbaseCatList(onSuccess.data);
   }
    },
    onFail => {
    console.log('Fail:', onFail);
    showToast(onFail)
    },
    onError => {
    console.error('Error:', onError);
    showToast("Somthing Wents to worng");
    })
}




  const handleSubmit = () => {
    
    if(IsEdit==false){
      const validationErrors = validateForm(Formdatas);
      if (Object.keys(validationErrors)?.length === 0) {
        CreateCat();
      } else {
          showToast("All fields are required");
      }
    }
    else{
      CreateCat();
    }
    
};

const validateForm = (formData) => {
  const errors = {};
  Object.keys(formData).forEach((fieldName) => {
      if (fieldName === "images") {
          if (!formData[fieldName] || formData[fieldName]?.length === 0) {
              errors[fieldName] = "Base image is required";
          }
      } else if (!formData[fieldName]?.trim()) {
          errors[fieldName] = `${fieldName} is required`;
      }
  });
  return errors;
};
   
  const [CatList, setCatList] = useState([]);
  const [catData, setcatData] = useState([])
  const [TotalPage, setTotalPage] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1)
  const [Formdatas, setFormdatas] = useState({
    name:"",
    slug:"",
    title:"",
    parent_id:"0",
    images:[],
    is_menu:"1",
    status:"1"
  })


  console.log(Formdatas,"mmmmm");


  const CreateCat = ()=>{
    const string = selectedIds.join(',');
    const contentState = editorState?.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    var formdata = new FormData();
    formdata.append("name", Formdatas?.name);
    formdata.append("slug", Formdatas?.slug);
    formdata.append("title", Formdatas?.title);
    formdata.append("description", htmlContent);
    formdata.append("post_ids", string);
    formdata.append("is_menu",Formdatas?.is_menu);
    formdata.append("status",Formdatas?.status);
    formdata.append("parent_id",Formdatas?.parent_id || 0);
{
  ImagesArray?.map((res)=>{
    formdata.append("images[]", res);
  }) 
}
 if(IsEdit==true){
  formdata.append("_method", "PUT");
}
    Post_WITH_TOKEN(IsEdit==true?`/api/categories/${IsEditId}`:"/api/categories",formdata,
    e=>{
      console.log(e)
      showToast(e.message || e.msg || "success");
      getCat(CurrentPage);
      // setTotalPage(1)
    },
    f=>{
      console.log(f)
      showToast(f.message || f.msg || "error")
    },
    n=>{
      console.log(n)
      showToast(n.message || "Network error")
    })
  }

  const ShowById = (id)=>{
    GET_WITH_TOKEN_PARAMS("/api/categories",id,
    onSuccess => {
      console.log('Success:', onSuccess);
     if(!!onSuccess){
      showToast(onSuccess?.message || onSuccess?.msg || "Success");
      console.log(onSuccess.message);
    const blocksFromHTML = convertFromHTML(onSuccess?.data?.description);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML);
    let data = EditorState.createWithContent(contentState);
    seteditorState(data);
    let imges = onSuccess?.data?.images?.map((res)=>{
      return res.img_path
    })
    // console.log(imges,"images")
    setImagesArray(imges);
    let ids = onSuccess?.data?.posts?.map((res)=>{
      return res.id
    })
    setSelectedIds(ids);
    setFormdatas(prevState => ({
      ...prevState, // Spread the previous state
      ...onSuccess?.data // Merge with the new data object
    }));
     setSelectedName(onSuccess?.data?.posts)
     }
      },
      onFail => {
      console.log('Fail:', onFail);
      showToast(onFail)
      },
      onError => {
      console.error('Error:', onError);
      showToast("Somthing Wents to worng")
    
      })
  }
  


  const getCat = async(page)=>{
    GET_WITH_TOKEN(`/api/allcategories?page=${page}`,"",
    onSuccess => {
      console.log('Success:', onSuccess);
     if(!!onSuccess){
      setCatList(onSuccess?.data?.data);
      setTotalPage(onSuccess?.data?.last_page);
      setCurrentPage(onSuccess?.data?.current_page);
     }
      },
      onFail => {
      console.log('Fail:', onFail);
      showToast(onFail)
      },
      onError => {
      console.error('Error:', onError);
      showToast("Somthing Wents to worng")
    
      })
  }

 

  // const getPostShow = async(page)=>{
  //   GET_WITH_TOKEN(`/api/posts?page=${page}`,"",
  //   onSuccess => {
  //     console.log('Success:', onSuccess);
  //    if(!!onSuccess){
  //     setPostList(onSuccess?.data?.data);
  //     setTotalPage(onSuccess?.data?.last_page);
  //     // setCurrentPage();
  //    }
  //     },
  //     onFail => {
  //     console.log('Fail:', onFail);
  //     showToast(onFail)
  //     },
  //     onError => {
  //     console.error('Error:', onError);
  //     showToast("Somthing Wents to worng")
    
  //     })
  // }



const onChange = (e,name)=>{
  setFormdatas({...Formdatas,[name]:e})
}

const [ImagesArray, setImagesArray] = useState([]);



const AddPostImages = (e)=>{
  console.log(e,"target");
  let file = e.target.files[0];
  const New = [...ImagesArray];
  New?.push(file)
  setImagesArray(New);
  const Array = [...Formdatas.images]
  Array.push({image:URL.createObjectURL(file)});

  setFormdatas({...Formdatas,images:Array});
 }

  
 const DeletePostImage =(index)=>{
  const ArrayNew = [...ImagesArray]
  ArrayNew.splice(index,1)
  setImagesArray(ArrayNew)
  const Array = [...Formdatas.images]
  Array.splice(index,1)
  setFormdatas({...Formdatas,images:Array});
 }

 const [selectedIds, setSelectedIds] = useState([]);
 const [selectedName, setSelectedName] = useState([]);


 const handleSelectionChange = (e,name) => {
  console.log(name);
  const selectedId = parseInt(e);
  if (!selectedIds.includes(selectedId)) {
    setSelectedIds([...selectedIds, selectedId]);
  }
};

const handleRemove = (selectedList, removedItem) => {
  console.log(removedItem, "removedItem");
  const updatedIds = selectedIds.filter(id => id !== removedItem?.id);
  setSelectedIds(updatedIds);
  // Your logic for handling the removal of items here
};

const EmpitSatate = ()=>{

}

const PublishData = [
  {id:1,name:"Yes",value:1},{id:0,name:"No",value:0}
]

const StatusData = [
  {id:1,name:"Active",value:1},{id:0,name:"InActive",value:0}
]
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-12  '>

           <div className='card'>
            <div className='card-header'>
              <p style={{fontSize:'25px'}}>Categories Product Filter</p>



              <div class="mb-3  mt-4 d-flex">
                 <input type="text" class="form-control " id="exampleFormControlInput1"
                  placeholder="Enter Name" style={{width:'20%'}}/>

                  <button type='btn' className='ms-4' style={{backgroundColor:'#008c93', color:'#fff', border:'none', padding:'7px 15px', borderRadius:'8px'}}>Search</button>

                  <button type='btn' className='ms-2' style={{backgroundColor:'transparent', color:'#000', padding:'7px 15px', borderRadius:'8px', border:'1px solid gray'}}>Reset</button>


                  <button onClick={()=>{}} type='btn' className='ms-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{backgroundColor:'#366fe9', color:'#000', padding:'7px 15px', borderRadius:'8px', border:'1px solid gray'}}>Add New</button>
              </div>
              <div>

              </div>
            </div>
            <div className='card-body' style={{height:"500px",overflowY:"scroll"}}>
<p style={{fontSize:'20px'}}>View All Products</p>

            <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">id</th>
      <th scope="col">image</th>
      <th scope="col">Name</th>
      <th scope="col">slug</th>
      <th scope="col">description</th>
      <th scope="col">post</th>
      <th scope="col">Parent</th>
      {/* <th scope="col">Country</th>
      <th scope="col">Actions</th> */}
    </tr>
  </thead>
  <tbody >
    {
      CatList?.length>0?
      CatList?.map((res)=>{
          return(
            <>
             <tr>
      <th scope="row">{res.id}</th>
      <td><div><img src={hello} alt='img' style={{width:'60px'}}/></div></td>
      <td>{res.name}</td>
      <td>{res.slug}</td>
      <td>40</td>
      <td>100</td>
      <td>no</td>
      <td>UEM</td>
      <td>
        <div >
          {/* <button className='py-2' style={{backgroundColor:'#3dc934', color:'#fff',
           border:'none', borderRadius:'4px'}}>Show</button> */}
          <button className='py-2 mx-2' style={{backgroundColor:'#3cc3b0', color:'#fff',
           border:'none', borderRadius:'4px'}} onClick={()=>{ShowById(res?.id);setIsEdit(true);setIsEditId(res?.id)}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >Edit</button>
          <button className='py-2' style={{backgroundColor:'#9ba322', color:'#fff',
           border:'none', borderRadius:'4px'}}>Delete</button>
        </div>
      </td>
    </tr>
            </>
          )
      }):null
    }
   
    
    
  </tbody>
</table>
{
  CatList?.length>0?null:
  <div className="data-not-found">
      <h2 className="bounce">Data Not Found</h2>
      <p className="fade-in">Sorry, the requested data could not be found.</p>
    </div>
}
            </div>
            <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
          <BasicPagination count={TotalPage}  onChange={(e)=>{getCat(e)}}/>
          </div>
           </div>




          </div>
        </div>
      </div>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width:'85%'}}>
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel"></h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>

  <div class="offcanvas-body">


<CommanInput value={Formdatas?.name}  placeholder={"Enter Name"} type='text' describtionShow={true} desTitle={" Name"} onChange={(e)=>{onChange(e,"name")}}/>
<CommanInput  value={Formdatas?.slug} placeholder={"Enter slug"} type='text' describtionShow={true} desTitle={" Slug"} onChange={(e)=>{console.log(e,"Formdata");onChange(e,"slug")}}/>
<CommanInput  value={Formdatas?.title} placeholder={"Enter Title"} type='text' describtionShow={true} desTitle={" Title"} onChange={(e)=>{console.log(e,"Formdata");onChange(e,"title")}}/>
<CommanDropDown useMultiselect={true} title={"Pls Select"} desTitle={" Retated Post"} onChange={(e,name)=>{handleSelectionChange(e,name)}} Data={PostList || []} slectedData={selectedName || []} onRemove={handleRemove}/>
<CommanDropDown title={"Pls Select"} desTitle={"Select parent category"} Data={baseCatList} onChange={(e)=>{onChange(e,"parent_id")}} value={Formdatas?.parent_id}/>

<div class="mb-2">
  <label for="exampleFormControlTextarea1" class="form-label" style={{fontWeight:'600'}}>Description</label>
  <div className='' style={{border:"1px solid gray"}}>
<Editor
  editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={onEditorStateChange}
/></div>
</div>


<CommanInput placeholder={"Images"} fileInput={true} input={false} textArea={false} type='text' describtionShow={true} desTitle={"Galary Images"} onChange={(e)=>{AddPostImages(e)}}/>
   
   <div className='w-100 row'>
   {
    Formdatas?.images?.map((res,index)=>{
      return(
        <>
        <div className='col-2'>
        <img className='w-100' src={res.image || res?.img_path} alt='#' style={{justifyContent:"center",alignItems:"center",width:100}}/>
        <button className='w-100' onClick={()=>{DeletePostImage(index)}}>
          Delete
        </button>
        </div>
        </>
      )
    })
   }
</div>

<CommanDropDown title={"Pls Meanu"} desTitle={"Select Meanu"} Data={PublishData} onChange={(e)=>{onChange(e,"is_menu")}} value={Formdatas?.is_menu}/>
<CommanDropDown title={"Pls Status"} desTitle={"Select Status"} Data={StatusData} onChange={(e)=>{onChange(e,"status")}} value={Formdatas?.status}/>


<div className='mt-5'>
  <Button data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>{handleSubmit()}}>Submit</Button>
</div>
  </div>
</div>
    </div>
  )
}

export default Cat;